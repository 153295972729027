// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// dev
// export const environment = {
//   production: false,
//   socketHttpProtocol: 'http',
//   socketAddress: 'localhost:3000',
//   httpProtocol: 'http',
//   address: 'localhost:3000'
// };

// preprod
export const environment = {
  production: false,
  socketHttpProtocol: 'https',
  socketAddress: 'stg.iothub.linkio.net:3030',
  httpProtocol: 'https',
  address: 'stg.iothub.linkio.net:3030'
};

// prod
// export const environment = {
//   production: false,
//  socketHttpProtocol: 'https',
//  socketAddress: 'prd.iothub.linkio.net:3030',
//  httpProtocol: 'https',
//  address: 'prd.iothub.linkio.net:3030'
// };

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
